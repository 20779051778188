import { render, staticRenderFns } from "./imports.vue?vue&type=template&id=10396dd0&scoped=true&"
import script from "./imports.vue?vue&type=script&lang=js&"
export * from "./imports.vue?vue&type=script&lang=js&"
import style0 from "./imports.vue?vue&type=style&index=0&id=10396dd0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10396dd0",
  null
  
)

export default component.exports