import request from '@/utils/request'


// 员工列表
export function getEmploy(data) {
  return request({
    url: '/api/salary/staff/infos/list',
    method: 'post',
    data
  })
}

// 删除员工
export function deleteEmploy(params) {
  return request({
    url: '/api/salary/staff/infos',
    method: 'delete',
    params
  })
}

// 单个员工详情
export function employOne(params) {
  return request({
    url: '/api/salary/staff/infos/one',
    method: 'get',
    params
  })
}

//  辅助核算中所有的部门
export function departmentsList(params) {
  return request({
    url: '/api/setting/accAssistingAccounting/departments',
    method: 'get',
    params
  })
}

//  计提工资科目
export function subjectList(params) {
  return request({
    url: '/api/setting/accountsubject/leaf/subjects/category',
    method: 'get',
    params
  })
}

// 员工导入
export function employImport(data) {
  return request({
    url: '/api/salary/staff/import',
    method: 'post',
    data
  })
}



